import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import iosmobile from "../helpers/images/iosmobile.png";
import dowanloadapp from "../helpers/images/dowanloadappAndroid.png";
import White_logo from "../helpers/images/White_logo.png";
import Aboutus from "../Aboutus/Aboutus";
import "./Android.scss";
import Nattersearch from "../helpers/videos/Nattersearch.mp4";
import NSVideo from "../helpers/images/NSVideo.jpeg";

const Android = () => {
  return (
    <>
      <div className="Android">
      <Container>
          <Row className="text-center">
            <Col xs={12}>
            <p></p><img src={White_logo} width="70%" className="White_logo" /><p></p>
            <video src={Nattersearch} poster={NSVideo} controls width="100%" />
            </Col>
            <Col xs={12}>
            <p></p><br></br><h2>Download Our App</h2>
              <h4>to demo and sign up</h4>
              
              
            </Col>
            <Col xs={12}>
              <img src={iosmobile} className="img-fluid" width={140} />
            </Col>
            <Col xs={12}>
              <a href="https://play.google.com/store/apps/details?id=com.nattersearch.ns">
                <img src={dowanloadapp} width="50%" className="img-fluid" />
              </a>
            </Col>
          </Row>
        </Container>
      </div>
      <Aboutus />
    </>
  );
};

export default Android;
