import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactStars from "react-stars";
import { Row, Col } from "react-bootstrap";
import { GoLocation } from "react-icons/go";
import Favourite from "../Favourite/Favourite";
import defaultImage from "../helpers/images/white-profile-icon-24.png";
import axios from "axios";
import group5126 from "../helpers/images/group5126.png";
import "./UserCard.scss";

const UserCard = ({ user }) => {
  const {
    id,
    name,
    IsFavourite,
    latitude,
    longitude,
    location,
    image,
    call_price,
  } = user;
  // const [location, setLocation] = useState(null);

  // const getLocation = async (latitude, longitude) => {
  //   if (latitude.length !== 0 && longitude.length !== 0) {
  //     try {
  //       const response = await axios.get(
  //         `https://us1.locationiq.com/v1/reverse.php?key=pk.9ddc8d900420336eb5d33f437cf1bd42&lat=${latitude}&lon=${longitude}&format=json`
  //       );
  //       setLocation(response.data.address.city);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  // };
  // useEffect(() => {
  //   getLocation(latitude, longitude);
  // }, [latitude, longitude]);

  let userImg = image.length ? image : defaultImage;

  return (
    <Row className="profile-page justify-content-center">
      <Col xs={12} md={10} lg={9}>
        <div className="card profile-header">
          <div className="body">
            {/* target="_blank" */}
            <Row>
              <Link to={`/userprofile/${id}`} className="col-12 d-flex">
                <Col xs={3} md={2} lg={1} className="px-0 ">
                  <div className="profile-image text-center">
                    {" "}
                    <img src={userImg} alt="" className="img-fluid" />{" "}
                  </div>
                </Col>
                <Col className="d-flex flex-column justify-content-center pr-0 pr-md-3">
                  <h5 className="m-0">
                    <strong>{name}</strong>
                  </h5>
                  {/* <span className="job_post">Ui UX Designer</span> */}
                  {location.length > 0 && (
                    <small className="location">
                      <GoLocation className="mb-2" /> {location}
                    </small>
                  )}
                  <ReactStars
                    className="ReactStars"
                    count={5}
                    size={16}
                    isHalf={true}
                    edit={false}
                    value={user ? parseFloat(user.AvgRate) : 2.5}
                    color1="#ced4da"
                    color2="#3c9b83"
                  />
                </Col>
                <Col
                  xs={4}
                  className="text-center d-flex flex-column justify-content-center pr-0 pr-md-3"
                >
                  {/* <Favourite favourite_to={id} IsFavourite={IsFavourite} /> */}
                  <span className="callPrice">
                    {call_price}&nbsp;
                    <span style={{ whiteSpace: "nowrap" }}>
                      &nbsp;
                      <img src={group5126} width="22%" />
                      &nbsp;Per call
                    </span>
                  </span>
                </Col>
              </Link>
            </Row>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default UserCard;
