import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import HzHomeImage from "../helpers/images/hzhomeimage3.jpg";
import vHomeImage from "../helpers/images/vhomeimage3.jpg";
import Nattersearch from "../helpers/videos/Nattersearch.mp4";
import NSVideo from "../helpers/images/NSVideo.jpeg";
import "./Aboutus.scss";

const Aboutus = () => {
  // const iOS = navigator.userAgent.match(/(iPad|iPhone|iPod)/i) ? true : false;
  // const paddingValue = iOS ? "17vh" : "";
  const aboutus = window.location.pathname.match(/^\/aboutus/) ? true : false;
  return (
    <div
      className={`aboutUs ${aboutus ? "py-0" : ""}
        `}
    >
      <Container>
        <Row className="justify-content-center">
    <Col xs={12} className="greenBackGround">
            <h2 align="center" className="py-2 mb-0 white text-md-center">
              How NatterSearch Works?
            </h2>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row className="justify-content-center">
          <Col>
            <p><br></br>
              <ul className="green">
                <li>
                  Download the NatterSearch app, or sign up on our website 
                </li>
                <li>
                  Add your expertise to your profile. First 1,000 users get 1,000 NS Coins each (valued at $1000 when the company goes public!) after adding their first topic
                </li>
                <li>
                  Hurry and be one of the founding users and earn free coins to use for calls or convert to money
                </li>
                <li>
                  Name your price in NS coins per call answered. Switch to "Available to natter" when you have time to receive in-app calls
                </li>
                <li>
                  People will rate your expertise at the end of the voice call,
                  which will build your reputation
                </li>
                <li>
                  When people search for a topic, a list of experts given will
                  be sorted based on rate, availability to natter, and price
                </li>
                <li>
                  Accumulate NS coins to convert to real money or use
                  to call experts to learn about any topic
                </li>
                <li>
                  Refer friends and get extra 10 NS coins for each friend who
                  signs up using your referral code available in the side menu
                </li>
              </ul>
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Aboutus;
