import React, { useState, useEffect, useContext, useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import ReactStars from "react-stars";
import Pagination from "../Pagination/Pagination";
import AuthContext from "../../store/auth-context";
import Loader from "../Loader/Loader";
import API from "../../utils/DevApi";
import { Row, Col } from "react-bootstrap";
import defaultImage from "../helpers/images/white-profile-icon-24.png";
import group5126 from "../helpers/images/group5126.png";
import "./History.scss";

const History = () => {
  const [calls, setCalls] = useState([]);
  const [loading, setLoading] = useState(false);
  const authCtx = useContext(AuthContext);
  const history = useHistory();

  let PageSize = 7;
  const [currentPage, setCurrentPage] = useState(1);

  const currentCallsData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return calls.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, calls]);

  async function fetchHistory() {
    setLoading(true);
    const token = localStorage.getItem("token");

    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await API.get("call/history", config);
      setCalls(response.data.data.calls);
      console.log(response.data.data.calls);
      setLoading(false);
    } catch (error) {
      if (error.response.status == "401") {
        localStorage.clear();
        authCtx.logout();
        window.location.reload();
        history.replace("/");
      }
      console.log(error);
    }
  }

  useEffect(() => {
    fetchHistory();
    localStorage.removeItem("searchQuery");
    localStorage.removeItem("usersResult");
  }, []);

  return (
    <div className="history">
      {loading ? (
        <Loader />
      ) : calls.length > 0 ? (
        <>
          {currentCallsData.map((call) => {
            return (
              <Row
                className="profile-page justify-content-center"
                key={call.id}
              >
                <Col xs={12} md={10} lg={8}>
                  <div className="card profile-header">
                    <div className="body">
                      <Row>
                        <Link
                          to={`/userprofile/${call.user_id}`}
                          className="col-12 d-flex"
                        >
                          <Col xs={3} md={1} className="px-0 ">
                            <div className="profile-image text-center">
                              {" "}
                              <img
                                src={
                                  call.image.length > 0
                                    ? call.image
                                    : defaultImage
                                }
                                className="img-fluid"
                                alt="profile"
                              />{" "}
                            </div>
                          </Col>
                          <Col className="d-flex flex-column justify-content-center pr-0 pr-md-3">
                            <h5 className="m-0">
                              <strong>{call.name}</strong>
                            </h5>
                            <small>{call.date}</small>
                            <ReactStars
                              className="ReactStars"
                              count={5}
                              size={16}
                              isHalf={true}
                              edit={false}
                              value={call ? parseFloat(call.AvgRate) : 2.5}
                              color1="#ced4da"
                              color2="#3c9b83"
                            />
                          </Col>
                          <Col
                            xs={4}
                            className="text-center d-flex flex-column justify-content-center pr-0 pr-md-3"
                          >
                            <span className="callPrice">
                              {call.call_price}&nbsp;
                              <span style={{ whiteSpace: "nowrap" }}>
                                &nbsp;
                                <img src={group5126} width="22%" />
                                &nbsp;Per call
                              </span>
                            </span>
                          </Col>
                        </Link>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            );
          })}
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={calls.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </>
      ) : (
        <h3 className="text-center">No call history</h3>
      )}
    </div>
  );
};

export default History;
